<template>
  <div>
    <q-form ref="editForm">
      <c-card title="기본정보" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <c-datepicker
              required
              :editable="editable && !isOld" 
              :disabled="disabled"
              label="점검일"
              type="date"
              name="patrolDate"
              v-model="patrol.patrolDate"
            />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <c-select
              type="edit"
              :editable="false"
              codeGroupCd="PATROL_TYPE_CD"
              itemText="codeName"
              itemValue="code"
              name="patrolTypeCd"
              label="점검구분"
              v-model="patrol.patrolTypeCd"
            ></c-select>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <c-dept
              required
              :disabled="disabled"
              :editable="editable && !isOld" 
              :plantCd="patrol.plantCd" 
              :label="popupParam.patrolTypeCd == 'PTC0000001' ? '점검 주관부서' : '점검 대상부서'"
              name="vendorCd"
              v-model="patrol.deptCd"
              @input="changeDeptCd"
            />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" v-if="popupParam.patrolTypeCd == 'PTC0000001'">
            <c-vendor
              required
              :disabled="disabled"
              :editable="editable && !isOld" 
              label="점검 대상업체"
              name="vendorCd"
              v-model="patrol.vendorCd"
              :selfFlag="false"
            />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <c-field
              required
              :disabled="disabled"
              :editable="editable && !isOld" 
              type="user"
              label="점검자"
              name="sopMapId"
              v-model="patrol.sopMapId">
            </c-field>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
            <c-plant 
              required
              :disabled="disabled"
              :editable="editable && !isOld" 
              type="edit" 
              name="plantCd" 
              v-model="patrol.plantCd"
              @datachange="datachange" />
          </div>
        </template>
      </c-card>
      <c-table
        ref="grid"
        title="점검결과"
        :isTitle="true"
        :columns="grid.columns"
        :gridHeightAuto="true"
        :data="patrol.results"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :hideBottom="true"
        :editable="editable && !disabled"
        rowKey="saiPatrolItemId"
        selection="multiple"
      >
        <template slot="suffixTitle">
          <font style="font-size:0.9em;font-weight:400;color:red;"> O:양호 △:미흡 X:불량</font>
        </template>
        <template slot="table-button">
          <q-btn-group outline>
            <c-btn
              v-if="editable && !disabled && patrol.patrolTypeCd == 'PTC0000002' && isENVHEADept"
              :showLoading="false"
              label="추가"
              icon="add"
              @btnClicked="addResult"
            />
            <c-btn
              v-if="editable && !disabled && patrol.results.length > 0 && patrol.patrolTypeCd == 'PTC0000002' && isENVHEADept"
              :showLoading="false"
              label="제외"
              icon="remove"
              @btnClicked="removeResult"
            />
          </q-btn-group>
        </template>
      </c-table>
      <c-mobile-table
        ref="gridimpr"
        :isTitle="true"
        title="유해위험요인 도출"
        :columns="gridimpr.columns"
        :gridHeightAuto="true"
        :data="patrol.imprs"
        :columnSetting="false"
        :isExcelDown="false"
        :usePaging="false"
        :filtering="false"
        noDataLabel="도출된 유해위험요인이 없습니다."
        :hideBottom="true"
        :editable="editable && !disabled"
        rowKey="saiPatrolImprId"
        selection="multiple"
      >
        <template slot="table-button">
          <q-btn-group outline>
            <c-btn
              v-if="editable && !disabled"
              :showLoading="false"
              label="추가"
              icon="add"
              @btnClicked="addImpr"
            />
            <c-btn
              v-if="editable && !disabled && patrol.imprs.length > 0"
              :showLoading="false"
              label="제외"
              icon="remove"
              @btnClicked="removeImpr"
            />
          </q-btn-group>
        </template>
      </c-mobile-table>
      <!-- <c-upload 
        label="관련파일"
        :attachInfo="attachInfo"
        :editable="editable&&!disabled">
      </c-upload> -->
    </q-form>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
        <q-btn-group outline >
          <!-- 평가완료 -->
          <c-btn
            :flat="true"
            v-show="!editable"
            label="작성 권한이 없습니다."
            icon="warning"
          />
          <c-btn 
            v-show="isOld && !disabled && editable"
            flat
            :isSubmit="isComplete"
            :url="completeUrl"
            :param="patrol"
            mappingType="PUT"
            label="점검완료" 
            icon="check"
            @beforeAction="completePatrol"
            @btnCallback="completePatrolCallback" />
          <c-btn
            v-show="editable&&!disabled"
            flat
            :url="saveUrl"
            :isSubmit="isSave"
            :param="patrol"
            :mappingType="saveType"
            label="저장"
            icon="save"
            @beforeAction="savePlan"
            @btnCallback="saveCallback" 
          />
          <c-btn 
            v-if="isOld && !deleteFlag && editable" 
            flat
            label="삭제" 
            icon="delete_forever" 
            @btnClicked="remove" />
        </q-btn-group>
      </div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'patrolInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiPatrolId: '',
        patrolTypeCd: null,
      }),
    },
    patrol: {
      type: Object,
      default: () => ({
        saiPatrolId: '',
        plantCd: '',
        deptCd: '',
        vendorCd: '',
        sopMapId: '',
        patrolTypeCd: '',
        patrolDate: '',
        patrolName: '',
        patrolResultCd: null,
        patrolResultSummary: '',
        patrolCompleteFlag: 'N',
        sopConstructionId: '',
        ramRiskAssessmentPlanId: '',
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        regUserId: '',
        chgUserId: '',
        results: [],
        deleteResults: [],
        patrolImproveModelList: [],
        imprs: [],
        deleteImprs: [],
      }),
    },
    attachInfo: {
      type: Object,
      default: () => ({
        isSubmit: '',
        taskClassCd: 'PATROL_RESULT',
        taskKey: '',
      }),
    },
    isOld: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      gridimpr: {
        columns: [],
        height: "280px",
      },
      grid: {
        columns: [
          {
            name: 'patrolItemName',
            field: 'patrolItemName',
            // 점검항목
            label: '점검항목',
            align: 'left',
            style: 'width:70%',
            sortable: false,
          },
          {
            required: true,
            name: 'patrolResultCd',
            field: 'patrolResultCd',
            // 점검결과
            label: '점검결과',
            align: 'center',
            style: 'width:20%',
            type: 'select',
            codeGroupCd: 'PATROL_RESULT_CHECK_CD',
            setHeader: true,
            sortable: false,
          },
        ],
        height: "600px",
        data: [],
      },
      editable: true,
      saveType: 'POST',
      resultItemUrl: '',
      saveUrl: '',
      checkUrl: '',
      checkUrl2: '',
      deleteUrl: '',
      approvalUrl: '',
      completeUrl: '',
      isSave: false,
      isComplete: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    deleteFlag() {
      /**
       * 시스템관리자, 안전관리자 권한을 가진자
       * or 문서 비활성화 되지 않았을때
       */
      return this.$_.indexOf(this.$store.getters.auths, 'SAGI000000') === -1 && this.$_.indexOf(this.$store.getters.auths, 'SAGI000003') === -1 ? 
        this.disabled : false;
    },
    isENVHEADept() { 
      return this.$_.indexOf(this.$store.getters.auths, 'SAGI000022') > -1 
    },
  },
  watch: {
    'patrol'() {
      this.$emit('transInfo', this.patrol);
    },
    // 'count'() {
    //   this.getDetail();
    // }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.resultItemUrl = selectConfig.sai.patrol.resultItem.list.url;
      this.saveUrl = transactionConfig.sai.patrol.insert.url;
      this.completeUrl = transactionConfig.sai.patrol.complete.url;
      this.deleteUrl = transactionConfig.sai.patrol.delete.url;
      this.checkUrl = selectConfig.sai.patrol.check.url;    
      this.checkUrl2= selectConfig.sai.patrol.check2.url;      

      this.setHeader();
      // code setting
      // list setting
    },
    closePopUps() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '닫으시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$emit('closePopup')
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    setHeader() {
      this.$comm.uploderSetting('PATROL_IMPR_RESULT').then(_result => {
        this.gridimpr.columns = [
          {
            required: true,
            name: 'hazardDisasterTypeCd',
            field: 'hazardDisasterTypeCd',
            label: '재해유형',
            align: 'center',
            type: 'select',
            codeGroupCd: 'HAZARD_DISASTER_TYPE_CD',
            sortable: false,
          },
          {
            required: true,
            name: 'patrolContents',
            field: 'patrolContents',
            // 지적사항
            label: '유해위험요인',
            align: 'left',
            type: 'textarea',
            sortable: false,
          },
          {
            name: 'attach',
            field: 'attach',
            label: '유해위험요인 사진',
            align: 'center',
            style: 'width:100px',
            type: 'attach',
            customSize: 'width: 70px !important;height: 70px !important',
            taskClassCd: 'PATROL_IMPR_RESULT',
            keyText: 'saiPatrolImprId',
            uploaderSetting: _result,
            sortable: false,
          },
        ]
      })
    },
    getDetail() {
      this.$emit('getDetail')
    },
    changeDeptCd() {
      if (!this.isOld) {
        this.$emit('getItems', this.patrol.plantCd, this.patrol.deptCd);
      }
    },
    closeAddPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.patrol.results, {
            saiPatrolItemId: _item.saiPatrolResultItemId,
          });
          if (index === -1) {
            this.patrol.results.splice(0, 0, {
              saiPatrolId: this.popupParam.saiPatrolId,
              saiPatrolItemId:_item.saiPatrolResultItemId,
              saiPatrolResultId: uid(),
              patrolItemName: _item.patrolItemName,
              patrolResultCd: 'PRCC000001',
              patrolContents: '',
              regUserId: this.$store.getters.user.userId,
              editFlag: "C",
            })
          }
        })
      }
    },
    addResult() {
      this.popupOptions.title = '점검 항목';
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'./patrolMasterPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = false;
      this.popupOptions.width = '99%';
      this.popupOptions.closeCallback = this.closeAddPopup;
    },
    removeResult() {
      let selectData = this.$refs["grid"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "안내", // 안내
          message: "선택된 항목이 없습니다.", // 선택된 항목이 없습니다.
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.patrol.deleteResults) this.patrol.deleteResults = [];
          if (this.$_.findIndex(this.patrol.deleteResults, {
                saiPatrolResultId: item.saiPatrolResultId,
              }) === -1 
            && item.editFlag !== "C"
          ) {
            this.patrol.deleteResults.push(item);
          }
          this.patrol.results = this.$_.reject(this.patrol.results, item);
        });
      }
    },
    savePlan() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.sai.patrol.update.url;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sai.patrol.insert.url;
        this.saveType = 'POST';
      }
      let resultMsg = '';
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          if (this.popupParam.patrolTypeCd == 'PTC0000001') {
            this.$http.url = this.$format(this.checkUrl, this.patrol.vendorCd, this.patrol.patrolDate);
            resultMsg = '동일한 업체의 점검 일자가 존재합니다.';
          } else {
            this.$http.url = this.$format(this.checkUrl2, this.patrol.deptCd, this.patrol.patrolDate, this.patrol.sopMapId);
            resultMsg = '동일한 대상부서/점검 일자/점검자 가 존재합니다.';
          }
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            if (this.isOld || _result.data === 0) {
              if (this.patrol.imprs.length > 0) {
                if (this.$comm.validTable(this.gridimpr.columns, this.patrol.imprs)) {
                  window.getApp.$emit('CONFIRM', {
                    title: '확인',
                    message: '저장하시겠습니까?', // 저장하시겠습니까?
                    // TODO : 필요시 추가하세요.
                    type: 'info', // success / info / warning / error
                    // 확인 callback 함수
                    confirmCallback: () => {
                      this.patrol.regUserId = this.$store.getters.user.userId
                      this.patrol.chgUserId = this.$store.getters.user.userId

                      this.isSave = !this.isSave
                    },
                    // 취소 callback 함수
                    cancelCallback: () => {
                    },
                  });
                }
              } else {
                window.getApp.$emit('CONFIRM', {
                  title: '확인',
                  message: '저장하시겠습니까?', // 저장하시겠습니까?
                  // TODO : 필요시 추가하세요.
                  type: 'info', // success / info / warning / error
                  // 확인 callback 함수
                  confirmCallback: () => {
                    this.patrol.regUserId = this.$store.getters.user.userId
                    this.patrol.chgUserId = this.$store.getters.user.userId

                    this.isSave = !this.isSave
                  },
                  // 취소 callback 함수
                  cancelCallback: () => {
                  },
                });
              }
            } else {
              window.getApp.$emit('ALERT', {
                title: '안내', // 안내
                message: resultMsg,
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.isOld) {
        this.$set(this.popupParam, 'saiPatrolId', _result.data)
        this.$set(this.attachInfo, 'taskKey', _result.data)
        this.$set(this.attachInfo, 'isSubmit', uid())
      }
      this.$emit('getDetail');
    },
    completePatrol() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '점검완료 시, 도출된 유해위험요인들은 안전보건팀에 전달됩니다.\r\n점검완료 처리하시겠습니까?', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.patrol.regUserId = this.$store.getters.user.userId
              this.patrol.chgUserId = this.$store.getters.user.userId

              this.isComplete = !this.isComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completePatrolCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.isOld) {
        this.$set(this.popupParam, 'saiPatrolId', _result.data)
        this.$set(this.attachInfo, 'taskKey', _result.data)
        this.$set(this.attachInfo, 'isSubmit', uid())
      }
      this.$emit('getDetail');
    },
    /* eslint-disable no-unused-vars */ 
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.saiPatrolId);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');

            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    datachange() {
      if (!this.isOld) {
        this.$emit('getItems', this.patrol.plantCd, this.patrol.vendorCd);
      }
    },
    addImpr() {
      this.patrol.imprs.splice(0, 0, {
        saiPatrolId: this.popupParam.saiPatrolId,
        deptCd: this.patrol.deptCd,
        saiPatrolImprId: uid(),
        patrolContents: '',
        patrolRequestDate: this.$comm.getToday(),
        remark: '',
        regUserId: this.$store.getters.user.userId,
        editFlag: "C",
      });
    },
    removeImpr() {
      let selectData = this.$refs["gridimpr"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "안내", // 안내
          message: "선택된 항목이 없습니다.", // 선택된 항목이 없습니다.
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.patrol.deleteImprs) this.patrol.deleteImprs = [];
          if (this.$_.findIndex(this.patrol.deleteImprs, {
                saiPatrolImprId: item.saiPatrolImprId,
              }) === -1 
            && item.editFlag !== "C"
          ) {
            this.patrol.deleteImprs.push(item);
          }
          this.patrol.imprs = this.$_.reject(this.patrol.imprs, item);
        });
      }
    },
    linkImprDetail(row) {
      this.popupOptions.title = '개선';
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.$emit("getDetail")
    },
  }
};
</script>
